const colors = {
  bg: "#eeeeee",
  text: "#191a19",
  textLight: "#eeeeee",
  black: "#0c0908",
  yellow: "#fac935",
  red: "#ce3a40",
  blue: "#203c5f"
}

export default colors